<template>
    <div class="btn-group dropright float-right">
        <button type="button" class="btn btnOpciones " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <font-awesome-icon icon="ellipsis-v"/>
            
        </button>
        <div id="dropchat" class="dropdown-menu" x-placement="right-start" style="position: absolute; transform: translate3d(111px, 0px, 0px); top: 0px; left: 0px; will-change: transform;">

            
            <a class="dropdown-item" @click="traspaso" data-toggle="modal" data-target="#traspasarChat"><i class="icon-chat-1"></i>Traspasar chat</a>
            <a class="dropdown-item" @click="copiar" ><font-awesome-icon icon="copy" /> Copiar numero</a>
            <a class="dropdown-item disabled d-none" ><i class="icon-contacto  "></i>Enviar por email</a>
            <a class="dropdown-item" :class="{'tQueja': this.tags.queja}" @click="etiquetas.queja=!etiquetas.queja, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tQueja" /> Queja</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tRH': this.tags.vacante}" @click="etiquetas.vacante=!etiquetas.vacante, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tRH" /> Vacante</a>
            
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tTConvenio': this.tags.convenio}" @click="etiquetas.convenio=!etiquetas.convenio, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tTConvenio" />Convenio</a>
            
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tPromociones': this.tags.promociones}" @click="etiquetas.promociones=!etiquetas.promociones, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tPromociones" /> Promociones</a>
            
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tTicket': this.tags.ticketperdido}" @click="etiquetas.ticketperdido=!etiquetas.ticketperdido, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tTicket" /> Seguimiento de ticket perdido</a>
            
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tlucha': this.tags.campanas}" @click="etiquetas.campanas=!etiquetas.campanas, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tlucha" /> Campañas</a>
           <!-- <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tCoviddemora': this.tags.covid_d}" @click="etiquetas.covid_d=!etiquetas.covid_d, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tCoviddemora" /> Demora COVID</a> 
            
            <a class="dropdown-item" :class="{'tConsulta': this.tags.consulta}" @click="etiquetas.consulta=!etiquetas.consulta, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tConsulta" /> No puedo consultar mis resultados</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tEcommerce': this.tags.devoluciones}" @click="etiquetas.devoluciones=!etiquetas.devoluciones, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tEcommerce" /> Devoluciones</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tcacu': this.tags.facturacion}" @click="etiquetas.facturacion=!etiquetas.facturacion, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tcacu" /> Facturación</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tLentes': this.tags.lentes}" @click="etiquetas.lentes=!etiquetas.lentes, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tLentes" /> Lentes</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tSpam': this.tags.spam}" @click="etiquetas.spam=!etiquetas.spam, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tSpam" /> Spam</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tUbicacion': this.tags.ubicacion}" @click="etiquetas.ubicacion=!etiquetas.ubicacion, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tUbicacion" /> Ubicacion </a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tPreciosPreparaciones': this.tags.pyp}" @click="etiquetas.pyp=!etiquetas.pyp, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tPreciosPreparaciones"  /> Precios y Preparaciones</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tOtros': this.tags.otros}" @click="etiquetas.otros=!etiquetas.otros, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tOtros" /> Otros</a>
            <a v-show="network!='WAmedicos'" class="dropdown-item" :class="{'tCitas': this.tags.citas}" @click="etiquetas.citas=!etiquetas.citas, etiquetar(etiquetas)" ><font-awesome-icon icon="tag" class="tCitas"/> No pude agendar mi cita</a>-->
            
           <a v-show="network=='WA'" class="dropdown-item" @click="despedidaProgramada()"><font-awesome-icon icon="user-slash"/> Paciente Atendido</a>
            <a v-show="network=='WA'" class="dropdown-item" @click="finalizarChatE()"><i class="icon-cursos"></i> Finalizar con encuesta</a>
            <a v-show="network=='WA'" class="dropdown-item" @click="fijarChat()"> <font-awesome-icon icon="thumbtack" rotation="90"/> Fijar Paciente</a>
           <a  class="dropdown-item bUltimo" @click="finalizarChat()"><i class="icon-ergonomicos"></i>Finalizar chat</a>

        </div>
    </div>
                
</template>
<style>
.eConvenio {
  background-color: #302dc7; }
  .tTConvenio{color: #302dc7 !important;}
  .tPromociones{color: #fa3447 !important;}
  .tUbicacion{color: #007BFF  !important;}
  .tPreciosPreparaciones{color: #28A745 !important;}
  .tOtros{color: #6C757D !important;}
  .tCitas{color: #FFC107  !important;}
  .eRH, .eTicket, .eEcommerce, .eConsulta, .eResultado, .eResultado1, .eResultado2, .eQueja, .elucha, .eLentes, .eCallcenter, .eAtendido,.eSpam,.eConvenio, .ePromociones, .eUbicacion,  .ePreciosPreparaciones, .eOtros, .eCitas {
  width: 10px;
  height: 5px;
  align-self: center;
  margin: 0.1rem 1rem 0.6rem;
  border-radius: 10px;
  visibility: visible; }
</style>
<script>  
import asesorService from '../services/asesor'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisV, faTag, faUserSlash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faEllipsisV, faTag, faUserSlash, faInfoCircle)
export default {
    
    name: 'OpcionesChat',
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    props: {
        espera: Boolean,
        tags:{},
        sender:null
    },
    data() { 
       return {
            senderid:sessionStorage.getItem('sender_actual'),
            etiquetas:this.tags,
            network: null,
        }
    },
    created(){
        this.networkActiva()
    },
    methods: {
        networkActiva(){
            this.network = asesorService.getNetwork()
        },
        abrirCerrar() {
            var info = document.getElementById("info")
            info.classList.toggle('mostrarInfo')
            this.$gtag.event('botón abrirCerrar',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        finalizarChat() {    
            this.$bus.$emit('actualizar-pacientes')
            this.$gtag.event('botón finalizarChat',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        fijarChat(){
            this.$bus.$emit('fijar-pacientes')
            this.$gtag.event('botón fijarPaciente',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        finalizarChatE() {    
            this.$bus.$emit('finalizar-pacientes')
            this.$gtag.event('botón finalizaPacientesEncuesta',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        finalizarChatSinSesion() {    
            this.$bus.$emit('finalizar-px-sinsesion')
            this.$gtag.event('botón pacienteSinSesion',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        despedidaProgramada() {    
            this.$bus.$emit('despedida-programada')
            this.$gtag.event('botón pacienteAtendido',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        traspaso(){
            this.$bus.$emit('sencillo', 1)
            this.$gtag.event('botón darClick_traspaso',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        pendiente(){  
            asesorService.pacienteEspera().then(()=>{    
                sessionStorage.removeItem('sender')
                sessionStorage.removeItem('sender_actual')
                asesorService.pacientesAtendiendo().then(()=>{    
                    this.$gtag.event('botón mandar_pendiente',{
                        'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                        'event_label': this.sender,
                        'value': 1
                    })
                })
            }) 
        },
        copiar(){
            this.senderid = sessionStorage.getItem('sender_actual')
            navigator.clipboard.writeText(this.senderid).then(() => {
                this.$gtag.event('botón copiar_numero',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
                })
            })
            .catch(e => {
                console.error(e)
            });    
        },
        etiquetar(tag){
            const regex = /"/gi;
            asesorService.etiquetar(this.sender, JSON.stringify(tag).replace(regex, `'`)).then(()=>{    
                this.$bus.$emit('pedirListaChats', 1)
                this.$gtag.event('etiquetarPaciente',{
                    'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                    'event_label': ('Etiquetó con: '+ JSON.stringify(tag)),
                    'value': 1
                })
            }) 
        },
        informacion(){
            this.$bus.$emit('infoMostrar') 
            this.$gtag.event('botón informacion',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        }
    }
}
</script>